// frontend/src/utils/frontend.js
import io from 'socket.io-client';
import logger from './logger.js'; // Optional: Implement a frontend logger if desired

class FrontendSocket {
    constructor(apiUrl) {
        this.apiUrl = apiUrl;
        this.socket = null;
        this.sessionId = null;
        this.eventHandlers = {};
    }

    // Initialize the Socket.IO connection
    initialize() {
        this.socket = io(this.apiUrl, {
            transports: ['websocket'], // Optional: Force WebSocket transport
            reconnection: true, // Enable automatic reconnection
            reconnectionAttempts: Infinity,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
        });

        // Handle connection success
        this.socket.on('connect', () => {
            logger.info('Socket.IO client connected');
            if (this.sessionId) {
                this.joinSession(this.sessionId);
            }
        });

        // Handle connection errors
        this.socket.on('connect_error', (err) => {
            logger.error('Socket.IO connection error:', err);
            if (this.eventHandlers['connect_error']) {
                this.eventHandlers['connect_error'](err);
            }
        });

        // Handle reconnection
        this.socket.on('reconnect', (attemptNumber) => {
            logger.info(`Socket.IO reconnected after ${attemptNumber} attempts`);
            if (this.sessionId) {
                this.joinSession(this.sessionId);
            }
        });

        // Handle disconnections
        this.socket.on('disconnect', (reason) => {
            logger.warn('Socket.IO disconnected:', reason);
            if (this.eventHandlers['disconnect']) {
                this.eventHandlers['disconnect'](reason);
            }
        });
    }

    // Connect to a specific giveaway session
    joinSession(sessionId) {
        if (!this.socket) {
            logger.error('Socket not initialized. Call initialize() first.');
            return;
        }

        this.sessionId = sessionId;
        this.socket.emit('join', sessionId);
        logger.info(`Joined session: ${sessionId}`);
    }

    // Listen to specific events
    on(event, callback) {
        if (!this.socket) {
            logger.error('Socket not initialized. Call initialize() first.');
            return;
        }

        this.socket.on(event, callback);
        logger.info(`Listening to event: ${event}`);
    }

    // Remove event listeners
    off(event, callback) {
        if (!this.socket) return;
        this.socket.off(event, callback);
        logger.info(`Stopped listening to event: ${event}`);
    }

    // Disconnect the Socket.IO client
    disconnect() {
        if (this.socket) {
            this.socket.disconnect();
            logger.info('Socket.IO disconnected');
        }
    }
}

// Singleton instance
const frontendSocket = new FrontendSocket(process.env.REACT_APP_API_URL);

export default frontendSocket;
