// src/components/SlotMachine.js
import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import Swal from 'sweetalert2';
import GameFiButton from './GameFiButton';
import shuffle from 'lodash.shuffle';
import confetti from 'canvas-confetti';
import './SlotMachine.css';
import EasterEggImage  from '../assets/images/rouletteImage.webp';

function SlotMachineSpinner({ entries, onFinished }) {
  const [isSpinning, setIsSpinning] = useState(false);
  const [displayNames, setDisplayNames] = useState([]);
  const [spinDuration, setSpinDuration] = useState(10); // default 10 seconds
  const [winner, setWinner] = useState('');
  const [countdown, setCountdown] = useState(0);

  const itemHeight = 50; // Height of each item in pixels
  const controls = useAnimation(); // Animation control
  const imageControls = useAnimation(); // Animation control for the image

  // Easing function for smoother slowdown
  const easeOutCubic = [0.22, 1, 0.36, 1];

  // Use useEffect to start and stop the image animation based on isSpinning
  useEffect(() => {
    if (isSpinning) {
      // Start the image zoom animation
      imageControls.start({
        scale: [1, 1.2, 1],
        transition: {
          duration: 2, // Duration of one zoom cycle
          repeat: Infinity,
          ease: 'easeInOut',
        },
      });
    } else {
      // Reset the image animation
      imageControls.stop();
      imageControls.set({ scale: 1 }); // Reset scale to initial state
    }
  }, [isSpinning, imageControls]);

  const handleSpinClick = async () => {
    if (isSpinning || Object.keys(entries).length === 0) return;

    // Reset the slot machine position
    await controls.start({ y: 0 });

    // Build displayNames array based on entries
    const namesArray = [];
    Object.entries(entries).forEach(([username, count]) => {
      for (let i = 0; i < count; i++) {
        namesArray.push(username);
      }
    });

    // Shuffle the namesArray to randomize the display
    let shuffledNames = shuffle(namesArray);

    // Ensure a minimum number of items for visual effect
    const minDisplayItems = 300; // Adjust as needed
    while (shuffledNames.length < minDisplayItems) {
      shuffledNames = shuffledNames.concat(shuffle(namesArray));
    }

    // Select the winner based on weighted probabilities
    const selectedWinner = selectWeightedRandomWinner(entries);
    setWinner(selectedWinner);

    // Remove one instance of the winner's name from the shuffled list
    const winnerIndex = shuffledNames.lastIndexOf(selectedWinner);
    if (winnerIndex !== -1) {
      shuffledNames.splice(winnerIndex, 1);
    }

    // Add the winner's name at the end
    shuffledNames.push(selectedWinner);

    // Update displayNames with the shuffled list
    setDisplayNames(shuffledNames);

    // Start the animation
    setIsSpinning(true);
    setCountdown(spinDuration);

    // Calculate the total distance to scroll
    const totalDistance = itemHeight * (shuffledNames.length - 1);

    // Adjust the total distance so the winner's name is centered
    const adjustedTotalDistance = totalDistance - itemHeight;

    // Start the slot machine animation
    controls.start({
      y: -adjustedTotalDistance,
      transition: {
        duration: spinDuration,
        ease: easeOutCubic,
      },
    });

    // Start countdown timer
    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(countdownInterval);
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);

    // Display the winner after the animation ends
    setTimeout(() => {
      setIsSpinning(false);
      setCountdown(0);

      Swal.fire({
        title: 'Congratulations',
        html: `The winner is <strong>${selectedWinner}</strong>`,
        icon: 'success',
        confirmButtonText: 'OK',
        background: '#1A202C',
        color: '#FFFFFF',
        confirmButtonColor: '#FF00E5',
        customClass: {
          popup: 'font-gamefi',
          title: 'swal2-title-custom',
        },
        willOpen: () => {
          // Trigger confetti when the alert opens
          confetti({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 },
          });
        },
      });
      onFinished(selectedWinner);
    }, spinDuration * 1000);
  };

  const handleSpinDurationChange = (e) => {
    setSpinDuration(parseInt(e.target.value));
  };

  return (
    <div className="slot-machine-container flex flex-col items-center">
      {/* Slot Machine */}
      <div className="slot-machine">
        <motion.div
          animate={controls}
          initial={{ y: 0 }}
          className="slot-machine-scroll"
        >
          {displayNames.map((name, index) => (
            <div
              key={index}
              className={`slot-machine-item ${
                name === winner && !isSpinning ? 'winner-item' : ''
              }`}
            >
              {name}
            </div>
          ))}
        </motion.div>
        {/* Overlay the image on top of the slot machine when spinning */}
        {isSpinning && (
          <motion.img
            src={EasterEggImage}
            alt="Easter Egg"
            className="slot-machine-image-overlay"
            animate={imageControls}
            initial={{ scale: 1 }}
            style={{ width: '100px', height: '100px' }} // Adjust size as needed
          />
        )}
      </div>
      {/* Spin Button and Duration Selector */}
      <div className="mt-4 flex flex-wrap items-center justify-center space-x-4">
        <GameFiButton
          onClick={handleSpinClick}
          disabled={isSpinning || Object.keys(entries).length === 0}
        >
          {isSpinning ? `${countdown}s` : 'Spin'}
        </GameFiButton>
        <div className="relative items-center space-x-2">
          <label htmlFor="spinDuration" className="w-24">
            Duration: {spinDuration}s
          </label>
          <input
            id="spinDuration"
            type="range"
            min="10"
            max="90"
            step="1"
            value={spinDuration}
            onChange={handleSpinDurationChange}
            className="w-32"
            disabled={isSpinning}
          />
        </div>
      </div>
    </div>
  );
}

// Helper function to select a winner based on weighted probabilities
function selectWeightedRandomWinner(entries) {
  const totalEntries = Object.values(entries).reduce(
    (acc, count) => acc + count,
    0
  );
  const randomEntry = Math.floor(Math.random() * totalEntries);

  let cumulativeEntries = 0;
  for (const [username, count] of Object.entries(entries)) {
    cumulativeEntries += count;
    if (randomEntry < cumulativeEntries) {
      return username;
    }
  }
}

export default SlotMachineSpinner;
