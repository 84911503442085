// frontend/src/utils/logger.js
const logger = {
    info: (message) => {
        console.info(`[INFO]: ${message}`);
    },
    warn: (message) => {
        console.warn(`[WARN]: ${message}`);
    },
    error: (message) => {
        console.error(`[ERROR]: ${message}`);
    },
    debug: (message) => {
        console.debug(`[DEBUG]: ${message}`);
    },
};

export default logger;
