// src/components/App.js
import React, { useState, useEffect, useRef } from 'react';
import frontendSocket from './utils/frontend'; // Import the frontendSocket instance
import SlotMachineSpinner from './components/SlotMachine'; // Import the custom slot machine component
import GameFiButton from './components/GameFiButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad } from '@fortawesome/free-solid-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons'; // For X (Twitter)
import { faTv } from '@fortawesome/free-solid-svg-icons'; // For Sanko TV
import * as THREE from 'three';
import NET from 'vanta/dist/vanta.net.min';
import './index.css';

function App() {
  const [sankoTvLink, setSankoTvLink] = useState('');
  const [wallet, setWallet] = useState('');
  const [dmtPerEntry, setDmtPerEntry] = useState(0.01);
  const [gifts, setGifts] = useState([]);
  const [entries, setEntries] = useState({});
  const [winner, setWinner] = useState('');
  const [sessionId, setSessionId] = useState(null);
  const [statusMessage, setStatusMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [totalDonations, setTotalDonations] = useState({});

  const [vantaEffect, setVantaEffect] = useState(null);
  const vantaRef = useRef(null);

  // Initialize Vanta background
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        NET({
          THREE: THREE, // Pass the THREE instance
          el: vantaRef.current,
          color: 0x00ff85, // Neon green color
          backgroundColor: 0x000000, // Black background
          points: 12.0,
          maxDistance: 20.0,
          spacing: 18.0,
          showDots: true,
        })
      );
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);

  useEffect(() => {
    // Initialize the Socket.IO connection
    frontendSocket.initialize();

    // Retrieve sessionId from localStorage on component mount
    const storedSessionId = localStorage.getItem('sessionId');
    if (storedSessionId) {
      setSessionId(storedSessionId);
      frontendSocket.joinSession(storedSessionId);
    }

    // Define event handlers
    const handleStatus = (data) => {
      setStatusMessage(data.message);
    };

    const handleError = (data) => {
      setErrorMessage(data.message);
    };

    const handleNewGift = (gift) => {
      setGifts((prevGifts) => [...prevGifts, gift]);
    };

    const handleWsClosed = (data) => {
      console.log(data.message);
      setErrorMessage('WebSocket connection closed');
    };

    const handleWsError = (data) => {
      console.error(data.message);
      setErrorMessage('WebSocket encountered an error');
    };

    const handleTipError = (data) => {
      console.error(data.message);
      setErrorMessage('Failed to process a tip');
    };

    // Handle session data synchronization
    const handleSessionData = (data) => {
      setEntries(data.entries || {});
      setGifts(data.gifts || []);
      setTotalDonations(data.totalDonations || {});
    };

    // Handle participant updates
    const handleUpdateEntries = ({ username, entryCount, totalDonated }) => {
      setEntries((prevEntries) => ({
        ...prevEntries,
        [username]: entryCount,
      }));

      setTotalDonations((prevTotalDonations) => ({
        ...prevTotalDonations,
        [username]: totalDonated,
      }));
    };

    // Register event listeners
    frontendSocket.on('status', handleStatus);
    frontendSocket.on('error', handleError);
    frontendSocket.on('new_gift', handleNewGift);
    frontendSocket.on('ws_closed', handleWsClosed);
    frontendSocket.on('ws_error', handleWsError);
    frontendSocket.on('tip_error', handleTipError);
    frontendSocket.on('session_data', handleSessionData);
    frontendSocket.on('update_entries', handleUpdateEntries); // Corrected event name

    // Cleanup on component unmount
    return () => {
      frontendSocket.off('status', handleStatus);
      frontendSocket.off('error', handleError);
      frontendSocket.off('new_gift', handleNewGift);
      frontendSocket.off('ws_closed', handleWsClosed);
      frontendSocket.off('ws_error', handleWsError);
      frontendSocket.off('tip_error', handleTipError);
      frontendSocket.off('session_data', handleSessionData);
      frontendSocket.off('update_entries', handleUpdateEntries); // Corrected off
      frontendSocket.disconnect();
    };
  }, [dmtPerEntry]);

  const startGiveaway = async () => {
    try {
      setLoading(true); // Start loading
      // Reset UI elements
      setStatusMessage('Connecting to server...');
      setErrorMessage('');
      setGifts([]);
      setEntries({});
      setWinner('');
      console.log(process.env.REACT_APP_API_URL);

      // Send POST request to /start endpoint
      const response = await fetch(`${process.env.REACT_APP_API_URL}/start`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sankoTvLink, walletAddress: wallet, dmtPerEntry }),
      });

      const data = await response.json();

      if (response.ok) {
        const { sessionId, message } = data;
        setSessionId(sessionId);
        setStatusMessage(message);

        // Store sessionId in localStorage
        localStorage.setItem('sessionId', sessionId);

        // Emit the 'join' event to subscribe to the session's Socket.IO room
        frontendSocket.joinSession(sessionId);
      } else {
        setErrorMessage(data.message || 'Failed to start giveaway.');
        setStatusMessage('');
      }
    } catch (error) {
      setErrorMessage('An unexpected error occurred.');
      console.error('Error:', error);
      setStatusMessage('');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const stopGiveaway = async () => {
    if (!sessionId) return;

    try {
      // Send POST request to /stop endpoint
      const response = await fetch(`${process.env.REACT_APP_API_URL}/stop`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ sessionId }),
      });

      const data = await response.json();

      if (response.ok) {
        // Remove sessionId from localStorage
        localStorage.removeItem('sessionId');
        setSessionId(null);
        setGifts([]);
        setEntries({});
        setWinner('');
        setStatusMessage(data.message || 'Giveaway stopped successfully.');
      } else {
        setErrorMessage(data.message || 'Failed to stop giveaway.');
      }
    } catch (error) {
      setErrorMessage('Error stopping giveaway.');
      console.error('Error:', error);
    }
  };

  return (
    <div
      ref={vantaRef}
      className="min-h-screen text-white font-gamefi relative overflow-hidden"
    >
      <div className="relative z-10 flex flex-col min-h-screen">
        {/* Header with pink border */}
        <header className="p-5 border-b-4 border-gamefi-pink text-center">
          <h1 className="text-4xl font-bold flex items-center justify-center">
            <FontAwesomeIcon icon={faGamepad} className="mr-3 text-gamefi-pink" />
            SankoTV Giveaway Tool
          </h1>
        </header>

        {/* Main Content */}
        <main className="flex-grow p-5 flex flex-col items-center justify-center">
          {!sessionId ? (
            // Step 1: Input Form
            <div className="w-full max-w-3xl mx-auto bg-gray-800 p-6 rounded-lg shadow-xl animate-glow">
              <h2 className="text-2xl font-bold mb-4">Step 1: Start Giveaway</h2>
              <div className="space-y-4">
                <div className="flex flex-col lg:flex-row lg:space-x-4">
                  <input
                    type="text"
                    placeholder="SankoTV Link"
                    value={sankoTvLink}
                    onChange={(e) => setSankoTvLink(e.target.value)}
                    className="flex-1 p-3 bg-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-gamefi-pink mb-4 lg:mb-0"
                  />
                  <input
                    type="text"
                    placeholder="Wallet Address"
                    value={wallet}
                    onChange={(e) => setWallet(e.target.value)}
                    className="flex-1 p-3 bg-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-gamefi-pink mb-4 lg:mb-0"
                  />
                </div>
                {loading ? (
                  <div className="mt-4 text-center text-white">
                    <p>Connecting to Sanko.TV...</p>
                  </div>
                ) : (
                  <div className="flex items-center">
                    <span className="mr-2">DMT per Entry:</span>
                    <input
                      type="number"
                      placeholder="DMT per Entry"
                      value={dmtPerEntry}
                      onChange={(e) => setDmtPerEntry(parseFloat(e.target.value))}
                      min="0.01"
                      step="0.01"
                      className="p-3 bg-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-gamefi-pink mr-4"
                    />
                    <GameFiButton onClick={startGiveaway}>
                      Start Giveaway
                    </GameFiButton>
                  </div>
                )}
              </div>
              {errorMessage && (
                <div className="mt-4 text-red-500">
                  <p>{errorMessage}</p>
                </div>
              )}
            </div>
          ) : (
            // Step 2: Giveaway Running
            <div className="w-full">
              <h2 className="text-2xl font-bold mb-4 text-center">
                Step 2: Running Giveaway
              </h2>
              {statusMessage && (
                <div className="my-4 text-center text-gamefi-green">
                  <p>{statusMessage}</p>
                </div>
              )}

              <div className="flex flex-col lg:flex-row justify-center items-start space-y-6 lg:space-y-0 lg:space-x-6">
                {/* Left: Slot Machine */}
                <div className="flex flex-col items-center">
                  {Object.keys(entries).length > 0 ? (
                    <SlotMachineSpinner
                      entries={entries}
                      onFinished={(winner) => setWinner(winner)}
                    />
                  ) : (
                    <div className="w-80 h-80 bg-gray-800 flex items-center justify-center rounded-lg">
                      <p className="text-gray-500">No entries yet</p>
                    </div>
                  )}
                  <div className="mt-4 space-x-4">
                    <GameFiButton onClick={stopGiveaway}>End Giveaway</GameFiButton>
                  </div>
                </div>

                {/* Middle: Participants List */}
                <div className="bg-gray-800 p-6 rounded-lg shadow-xl w-full max-w-lg">
                  <h3 className="text-xl font-bold mb-4">Participants</h3>
                  <table className="w-full text-left">
                    <thead>
                      <tr>
                        <th className="pb-2">Username</th>
                        <th className="pb-2">Total DMT</th>
                        <th className="pb-2">Entries</th>
                      </tr>
                    </thead>
                    <tbody className="space-y-2 max-h-60 overflow-y-auto custom-scrollbar">
                      {Object.keys(entries).map((username, index) => (
                        <tr key={index} className="bg-gray-700 p-2 rounded">
                          <td className="py-1">{username}</td>
                          <td className="py-1">
                            {totalDonations[username]?.toFixed(2) || '0'}
                          </td>
                          <td className="py-1">{entries[username]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {/* Right: Streamer Commands Information */}
                <div className="bg-gray-800 p-3 rounded-lg shadow-md max-w-xs">
                  <h3 className="text-lg font-bold mb-2">Chat Commands:</h3>
                  <p className="mb-1 text-sm">Use the following command in chat to add entries for a user:</p>
                  <code className="bg-gray-700 p-2 rounded block text-xs">
                    /addentry username numberOfEntries
                  </code>
                  <p className="mt-2 text-xs text-gray-400">
                    Example: <code>/addentry JohnDoe 5</code>
                  </p>
                </div>
              </div>

              {errorMessage && (
                <div className="mt-4 text-red-500">
                  <p>{errorMessage}</p>
                </div>
              )}
            </div>
          )}
        </main>

        {/* Footer with pink border */}
        <footer className="p-5 border-t-4 border-gamefi-pink text-center">
          <div className="flex items-center justify-center space-x-6">
            <a
              href="https://x.com/YuppichBig"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-gamefi-pink hover:text-gamefi-orange transition-colors duration-300"
            >
              <FontAwesomeIcon icon={faTwitter} size="2x" className="mr-2" />
              <span>X Profile</span>
            </a>
            <a
              href="https://sanko.tv/YuppichBig"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-gamefi-pink hover:text-gamefi-orange transition-colors duration-300"
            >
              <FontAwesomeIcon icon={faTv} size="2x" className="mr-2" />
              <span>Sanko TV</span>
            </a>
          </div>
          <p className="mt-4">
            © 2024 Yuppy{' '}
            <a
              href="https://www.bepartofsanko.xyz/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gamefi-pink hover:text-gamefi-orange transition-colors duration-300"
            >
              Be Part of Sanko
            </a>
          </p>
        </footer>
      </div>
    </div>
  );
}

export default App;
