// src/components/GameFiButton.js
import React from 'react';

function GameFiButton({ children, onClick, disabled }) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`px-6 py-3 bg-gamefi-pink text-gray-900 font-bold rounded-full shadow-lg hover:bg-gamefi-orange hover:text-white transition-all duration-300 ${
        disabled ? 'opacity-50 cursor-not-allowed' : ''
      }`}
    >
      {children}
    </button>
  );
}

export default GameFiButton;
